<template>
    <div class="content-box">
        <div class="cont-header">
           <a-row>
            <a-col :span="1"></a-col>
            <a-col :span="11">
                <span :selected="vipType==1" @click="onClickAuth(1)">订阅会员</span>
                <span :selected="vipType==2" @click="onClickAuth(2)" style="margin-left:10%;">单曲购买</span>
                <span :selected="vipType==3" @click="onClickAuth(3)" style="margin-left:10%;">超值套餐</span>
            </a-col>
            <a-col :span="12" v-if="vipType==1" style="text-align:right;padding-right:5%;">
                <a-radio-group v-model="authType" @change="onClickVipAuth">
                    <a-radio :value="1">
                        企业VIP
                    </a-radio>
                    <a-radio :value="2">
                        个人VIP
                    </a-radio>
                </a-radio-group>
            </a-col>
            <a-col :span="12" v-if="vipType==2" style="text-align:right;padding-right:5%;">
                <a-radio-group v-model="licensedType" @change="onClickSel">
                    <a-radio :value="999">
                        全部音乐
                    </a-radio>
                    <a-radio :value="0">
                        未授权音乐
                    </a-radio>
                    <a-radio :value="1">
                        已授权音乐
                    </a-radio>
                </a-radio-group>
            </a-col>
            <a-col :span="12" v-if="vipType==3" style="text-align:right;padding-right:5%;">
                <a-radio-group v-model="packageType" @change="onclickPackage">
                    <a-radio :value="0">
                        未授权音乐
                    </a-radio>
                    <a-radio :value="1">
                        已授权音乐
                    </a-radio>
                </a-radio-group>
            </a-col>
           </a-row> 
           
        </div>
        <div class="cont">
            <authlog-list :dataTableList="dataTableList" v-if="vipType==1" :pagination="pagination"></authlog-list>
            <music-list :dataTableList="dataTableList" v-if="vipType==2" :pagination="pagination"></music-list>
            <package-list :params="packageParams" v-if="vipType==3" :isAuth="!isAuth"></package-list>
        </div>
    </div>
</template>
<script>
import authlogList from './components/authlogList.vue'
import musicList from './components/musicList.vue'
import packageList from './components/packageList.vue'
export default {
    data() {
      return {
          vipType: 1,
          licensedType: 999, //授权音乐筛选
          packageType:0,
          isAuth: true,
          authType: 1,  //vip授权筛选
          pagination: {
            "show_pagin" : true,
            "show-quick-jumper": true,
            "default-current":1,
            total:0,
            pageSize: 20,
            current: 0,
            onChange: this.onChangePage,
            hideOnSinglePage: true,
            style:{
                "float": "none",
                "text-align": "center",
            },
          },
          dataTableList:[],
          packageParams: '',
          curPage:0,
      }
    },
    components: {
      authlogList,
      musicList,
      packageList,
    },
    created: function () {
      this.getTableData();
    },
    methods: {
        onClickAuth(idx){
            this.dataTableList = [];
            this.vipType = idx;
            this.authType = 1;
            this.packageType = 0;
            this.licensedType = 999;
            this.pagination.current = 0;
            this.pagination.total = 0;
            this.getTableData();
        },
        getTableData(params){ //获取音乐授权信息
            if(this.vipType==1){
                let newParams = {
                    'is_licensed': 1,
                    'goods_type': 2,
                    'auth_type': this.authType,
                    page:this.pagination.current
                };
                
                this.$axios.VipAuthList(newParams).then(res => {
                    if (res.data.code == 0) {
                        this.dataTableList = res.data.data.list;
                        this.pagination.total = res.data.data.total;
                    }
                })
            }
            if(this.vipType==2){
                let is_licensed = this.licensedType;
                if(is_licensed == '999'){
                    is_licensed = '';
                }
                let newParams = {
                    'is_licensed': is_licensed,
                    'goods_type': 1,
                };
                if(params) {
                    newParams.page = params.page;
                }
                this.$axios.AuthList(newParams).then(res => {
                    if (res.data.code == 0) {
                        this.dataTableList = res.data.data.list;
                        this.pagination.total = res.data.data.total;
                    }
                })
            }
            if(this.vipType==3){
                let is_licensed = this.packageType;
                let newParams = {
                    'is_licensed': is_licensed,
                    'goods_type': 2,
                };
                if(params) {
                    newParams.page = params.page;
                }
                this.packageParams = newParams;
            }
        },
        onChangePage(nowpage) {
            let params = {
                page:nowpage,
            }
            this.pagination.current = nowpage;
            this.pagination = Object.assign({}, this.pagination);
            this.getTableData(params);
            document.documentElement.scrollTop = 0;
        },
        onClickSel(e){
            this.pagination.current = 0;
            this.pagination.total = 0;
            this.licensedType = e.target.value;
            this.getTableData();
        },
        onclickPackage(e){
            this.pagination.current = 0;
            this.pagination.total = 0;
            this.packageType = e.target.value;
            this.isAuth = !e.target.value;
            this.getTableData();
        },
        onClickVipAuth(e){
            this.pagination.current = 0;
            this.pagination.total = 0;
            this.auth_type = e.target.value;
            this.getTableData();
        },
    },
}
</script>
<style lang="scss" scoped>
    .cont-header{
        line-height: 66px;
        border-bottom:1px solid #F0F4F5;
        span{
            display: inline-block;
            line-height: 66px;
            font-size: 16px;
            font-weight: 500;
            color: #333333;
        }
        span[selected]{
            color: #FF7B13;
            border-bottom: 2px solid #FF7B13;
        }
        span:hover{
            cursor: pointer;
            color: #FF7B13;
            border-bottom: 2px solid #FF7B13;
        }
    }
    .cont{
        width: 90%;
        margin: 0 auto;
        padding-top:24px;
    }
</style>