<template>
  <ng-dialog :visible.sync="show" :bodyStyle="bodyStyle" @close="$emit('update:visible', false)">
    <template v-slot:title>
      <h1 class="ant-drawer-title">{{$t('userOrder.activeDiaTitle')}}</h1>
      <div class="font-base lh-1-5">请填写授权信息以完成激活，我们将为你出具授权证书并提供可下载的音频</div>
    </template>

    <a-form :form="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <h2 class="font-lg m-bottom-md">完善联系人信息</h2>

      <a-form-item :label="$t('userOrder.labelContact')">
        <a-input
          size="large"
          :placeholder="$t('userOrder.pdContactName')"
          v-decorator="['contacter', { rules: [$root.$validate.required], validateTrigger: 'blur'}]"
        ></a-input>
      </a-form-item>

      <a-form-item :label="$t('userOrder.labelContactMethod')">
        <a-input
          size="large"
          :placeholder="$t('userOrder.pdContactMethodOfAuth')"
          v-decorator="['contact', { rules: [$root.$validate.required, $root.$validate.phone], validateTrigger: 'blur'}]"
        ></a-input>
      </a-form-item>

      <h2 class="font-lg m-bottom-md">完善授权信息</h2>

      <a-form-item :label="$t('userOrder.labelAuthType')" class="lh-unset-control">
        <a-radio-group
          :options="ActiviateMusicAuthTypeOptions.map(item => ({...item, label: `${item.label}使用`}))"
          v-decorator="['type', { initialValue: $val.ActiviateMusicAuthType.company}]"
        ></a-radio-group>
      </a-form-item>

      <a-form-item :label="$t('userOrder.labelAuthMain')">
        <a-input
          size="large"
          :placeholder="$t('userOrder.pdAuthMain')"
          v-decorator="['main', { rules: [$root.$validate.required], validateTrigger: 'blur'}]"
        ></a-input>
      </a-form-item>

      <a-form-item :label="$t('userOrder.labelAuthProject')">
        <a-input
          size="large"
          :placeholder="$t('userOrder.pdAuthProject')"
          v-decorator="['project', { rules: [$root.$validate.required], validateTrigger: 'blur'}]"
        ></a-input>
      </a-form-item>

      <a-form-item :label="$t('userOrder.labelAuthChannel')">
        <a-input
          size="large"
          :placeholder="$t('userOrder.labelAuthChannelPlaceholder')"
          v-decorator="['put_path', { rules: [$root.$validate.required], validateTrigger: 'blur'}]"
        ></a-input>
      </a-form-item>
    </a-form>

    <p class="font-sm">授权项目信息填写不清晰可能导致授权书无效，请根据项目实际情况如实且详尽的填写授权信息以保证您的合法权益。</p>

    <template slot="footer">
      <a-button
        type="primary"
        class="w-100"
        size="large"
        @click="handleOk()"
        :loading="confirmLoading"
      >{{$t('common.submit')}}</a-button>
    </template>
  </ng-dialog>
</template>

<script>
  import NgDialog from '@/components/NgDialog.vue';
  import {dialogMixins} from '@/mixins/index';
  import {ActiviateMusicAuthTypeOptions} from '@/config/options';
  import {formatTime} from '@/utils/function';
  import {orderType} from '@/config/options.value';

  const labelCol = {span: 24};
  const wrapperCol = {span: 24};

  export default {
    data() {
      return {
        show: false,

        confirmLoading: false,

        labelCol,
        wrapperCol,

        ActiviateMusicAuthTypeOptions
      }
    },

    beforeCreate() {
      this.form = this.$form.createForm(this, {
      });
    },

    computed: {
      isPackage() {
        return this.type === this.$val.orderType.package
      },

      // submitFunc() {
      //   return this.isPackage ? this.$axios.AuthAdd : this.$axios.AuthEdit
      // },

      submitFunc() {
        // return this.$axios.AuthAdd;
        return this.$axios.AuthEdit
      },
    },

    components: {
      NgDialog
    },

    props: {
      data: Object,
      type: {
        type: String,
        default: orderType.music
      }, // music package
    },

    mixins: [dialogMixins],

    methods: {
      handleOk() {
        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            let {auth_scene = '', use_mode = '', auth_time = '', auth_area = '', license_id, goods_id, goods_type} = this.data;

            // values.music_id = music_id
            values.auth_scene = auth_scene
            values.use_mode = use_mode
            values.auth_time = auth_time
            values.auth_area = auth_area
            values.goods_type = goods_type

            // 需要修改
            // values.goods_id = this.isPackage ? goods_id : '100';
            values.goods_id = goods_id;
            values.license_ids = [license_id];

            this.ActiviateOrder(values);
          }
        });
      },

      ActiviateOrder(params) {
        this.confirmLoading = true;

        this.submitFunc(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            this.$message.success(this.$t('userOrder.activeSuccess'));
            this.showCartDialog(false);
            this.$emit('success', data)
            this.$emit('loadlist')
          }

          this.confirmLoading = false;
        })
      },

      preview() {
        this.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            let scope = Object.assign({}, this.data, values);

            scope.create_at = formatTime(new Date());

            let routeUrl = this.$router.resolve({
              path: '/authbook/-1',
              query: scope,
            });

            window.open(routeUrl.href, '_blank');
          }
        });
      },

      showCartDialog(bool) {
        this.$emit('update:visible', bool);
      },

      // handleCancel() {
      //   this.showCartDialog(false);
      // },

      // closeModal() {
      //   this.showCartDialog(false);
      // }
    }
  }
</script>

<style lang="scss" scoped>
  .dialog-shop-cart {
    /deep/ {
      .ant-modal-body {
        padding-left: 50px;
        padding-right: 50px;
        min-height: 390px;
      }

      .ant-radio-group {
        margin-top: -10px;
      }

      .ant-radio-button-wrapper {
        margin-right: 15px;
        margin-top: 10px;
      }

      .ant-form-item-label {
        font-weight: bold;
      }

      .ant-form-item-control-wrapper {
        padding-left: 10px;
      }
    }
  }
</style>
