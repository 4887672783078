<template>
  <div>
    <a-table 
        :columns="columns" 
        :data-source="dataTableList" 
        style="border: 1px solid #EBF0F2;" 
        :pagination="pagination"
        :loading="loading"
        :rowKey="(scope) => scope.license_id"
    >
        <template slot="logo_url" slot-scope="text, scope" class="logo-info">
            <a-row class="opera_img">
                <img :src="`${scope.logo_url}`" style="width:40%;border-radius: 4px;display:inline-block;">
                <img src="@/assets/images/web/info_play.svg"
                v-if="!isPlaying(scope.music_id)" 
                @click="onClickIcon(scope)"
                />
                <img src="@/assets/images/web/pouse.svg"
                v-else
                @click="onPause(scope.music_id)"
                />
                <span style="display:inline-block;padding-top:24px;padding-left:12px;width:60%;overflow:hidden;text-overflow:ellipsis;white-space: nowrap;">
                    <router-link :to="`/musicDetail/${scope.music_id}`" >
                        <span style="font-size: 16px;font-weight: 600;color: #333333;">{{scope.music_name}}</span>
                    </router-link>
                    <br/>
                    <span style="font-size: 14px;font-weight: 400;color: #333333;">{{scope.suplr_name}}</span>
                </span> 
             </a-row>
        </template>
    

        <template slot="auth_name" slot-scope="text, scope">
            <span v-if="scope.auth_type==1">企业商用</span>    
            <span v-if="scope.auth_type==2">个人授权</span>    
        </template>

        <template slot="create_at" slot-scope="text, scope">
            <span>{{scope.create_at}}</span>    
        </template>

        <template slot="auth-info" slot-scope="text, scope">
            <div v-if="scope.auth_type==2">
                <span style="display:block;"><img src="@/assets/images/web/member/agreement.svg"/>个人授权协议</span>
                <span style="display:block;"><img src="@/assets/images/web/member/agreement-no.svg"/>非商用</span>
                <router-link :to="`/personal_scope/${scope.license_id}`" target="_blank"><p style="color:#333333;"><img src="@/assets/images/web/member/search.svg"/>查看授权范围</p></router-link>
            </div>
            <div v-if="scope.auth_type==1">
                <span style="display:block;"><img src="@/assets/images/web/member/agreement.svg"/>商用授权协议</span>
                <span style="display:block;"><img src="@/assets/images/web/member/agreement-yes.svg"/>可做商用</span>
                <router-link :to="`/company_scope/${scope.license_id}`" target="_blank"><p style="color:#333333;"><img src="@/assets/images/web/member/search.svg"/>查看授权范围</p></router-link>
            </div>
        </template>

        <template slot="operation" slot-scope="text, scope">
            <span style="cursor:pointer;">
                <router-link
                :to="`/vipauthCenter?license_id=${scope.code}`"
                >查看授权信息</router-link>
            </span><br/>        
            <a-button
            class="p-empty m-right-sm lh-1"
            type="link"
            @click="donwloadHighMusic(scope, true)"
            >下载无损音乐</a-button>
        </template>
    </a-table>
  </div>
</template>

<script>
  import {Table as ATable, tooltip as ATooltip} from 'ant-design-vue';
  import musicHandle from '@/mixins/musicHandle'
  export default {
    data() {
      return {
        loading: false,
        columns:[
            {
                title: '授权作品信息',
                dataIndex: 'logo_url',
                isSlot: true,
                scopedSlots: { customRender: 'logo_url' },
                width: 220,
            },
            {
                title: '授权类型',
                dataIndex: 'auth_name',
                scopedSlots: { customRender: 'auth_name' },
                width: 90,
            },
            {
                title: '下载时间',
                dataIndex: 'create_at',
                scopedSlots: { customRender: 'create_at' },
                // width: 100,
            },
            {
                title: '授权信息记录',
                dataIndex: 'key',
                scopedSlots: { customRender: 'auth-info'},
                // width: 200,
            },
            {
                title: '操作',
                dataIndex: 'operation',
                scopedSlots: { customRender: 'operation'},
            }
        ],
      }
    },

    props: {
        dataTableList: {
            type: Array
        },
        vipType:{
            type: Number,
        },
        pagination:{}
    },

    mixins: [musicHandle],

    components: {
        ATable,
        ATooltip
    },

    created: function () {
    },

    methods: {
        isPlaying (now_id) {
            let state = this.$store.state
            if (!state.globalVideoInfo) return false
            let { music_id, url } = state.globalVideoInfo
            return (
                state.globalVideoPlaying &&
                music_id &&
                url &&
                now_id === music_id
            )
        },
        onPause () {
            this.$store.commit('changeVideoPlaying', false)
        },
        onClickIcon (item) {
            this.isPlaying(item.music_id) ? this.onPause() : this.onPlay(item)
            this.$store.commit('changeVideoList', this.list || [])
        },
        onPlay (item) {
            let musicInfo = item
            musicInfo.url = musicInfo.audition_url
            this.$store.commit('changeVideoInfo', item)
            this.$nextTick(() => {
                this.$store.commit('changeVideoPlaying', true)
            })
            this.goPlayMusic(musicInfo)
            this.play_music_id = musicInfo.music_id;
        },
    }
  }
</script>

<style lang="scss" scoped>
    /deep/{
        table{
            border-collapse: separate;
            width: 100%;
            table-layout:fixed;
        }
    }
    .logo-info{
        cursor: pointer;
        border: 1px solid red;
    }
    .opera_img{
        img:not(:nth-child(1)){
            display:none;
            width:20px;
            position: absolute;
            margin-left: -53px;
            margin-top:55px;
            cursor:pointer;
        }
        &:hover{
            img:not(:nth-child(1)){
                display:inline-block;
            }
            .log_url_cell{
                opacity: 0.9;
            }

        }
    }
</style>
