<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="dataTableList"
      style="border: 1px solid #ebf0f2"
      :pagination="pagination"
      :loading="loading"
      :rowKey="
        (record, index) => {
          return index;
        }
      "
      :showHeader="false"
    >
      <template slot="logo_url" slot-scope="text, scope" class="logo-info">
        <a-row class="opera_img">
          <img :src="`${scope.logo_url}`" class="log_url_cell" />
          <img
            src="@/assets/images/web/info_play.svg"
            v-if="!isPlaying(scope.music_id)"
            @click="onClickIcon(scope)"
          />
          <img
            src="@/assets/images/web/pouse.svg"
            v-else
            @click="onPause(scope.music_id)"
          />
        </a-row>
      </template>

      <template slot="user-info" slot-scope="text, scope">
        <router-link :to="`/musicDetail/${scope.music_id}`">
          <a-tooltip placement="topRight" :title="`${scope.music_name}`">
            <p
              class="ellipsis"
              style="font-size: 16px; font-weight: 600; color: #333333"
            >
              {{ scope.music_name }}
            </p>
          </a-tooltip>
        </router-link>
        <router-link :to="`/supplierInfo/${scope.suplr_id}`">
          <p
            class="ellipsis"
            style="font-size: 14px; font-weight: 400; color: #333333"
          >
            {{ scope.suplr_name }}
          </p>
        </router-link>
      </template>

      <template slot="auth_name" slot-scope="text, scope">
        <a-col :span="24">
          {{ $t("music.labelUseMethod") }}: {{ scope.use_mode }}
        </a-col>
        <a-col :span="24">
          {{ $t("music.labelUseScene") }}: {{ scope.auth_scene | toAuthScene }}
        </a-col>
        <a-col :span="24">
          {{ $t("music.labelAuthArea") }}: {{ scope.auth_area | toArea }}
        </a-col>
        <a-col :span="24">
          {{ $t("music.labelAuthTime") }}: {{ scope.auth_time | toAuthPeriod }}
        </a-col>
        <a-col
          v-if="scope.is_licensed != $val.CommonCheck.fail && scope.code"
          :span="24"
        >
          授权代码: {{ scope.code }}
        </a-col>
      </template>

      <template slot="operation" slot-scope="text, scope">
        <a-button
          v-if="scope.is_licensed === $val.CommonCheck.correct"
          class="p-empty m-right-sm lh-1"
          type="link"
          @click="donwloadHighMusic(scope, true)"
          >下载无损音乐</a-button
        >
        <p
          v-if="scope.is_licensed == $val.CommonCheck.fail"
          style="cursor: pointer"
        >
          <a-button
            class="p-empty lh-1"
            type="link"
            @click="goToActivate(scope)"
            >激活音乐授权</a-button
          >
        </p>
        <p v-if="scope.is_licensed == 1" style="cursor: pointer">
          <router-link :to="`/authCenter?license_id=${scope.code}`"
            >查看授权信息</router-link
          >
        </p>
      </template>
    </a-table>
    <dialog-active-auth
      :visible.sync="showDialogActive"
      :data="handleData"
      @success="onSuccess"
      :type="$val.orderType.music"
    ></dialog-active-auth>
  </div>
</template>

<script>
import { Table as ATable, tooltip as ATooltip } from "ant-design-vue";
import DialogActiveAuth from "@/pages/components/order/DialogActiveAuth.vue";
import musicHandle from "@/mixins/musicHandle";
export default {
  data() {
    return {
      loading: false,
      columns: [
        {
          title: "授权作品信息",
          dataIndex: "logo_url",
          isSlot: true,
          scopedSlots: { customRender: "logo_url" },
          width: "10%",
        },
        {
          title: "",
          dataIndex: "scm_name",
          isSlot: true,
          scopedSlots: { customRender: "user-info" },
          width: "10%",
        },
        {
          title: "授权类型", //授权类型: 3:企业商用 2:个人授权 1:单曲购买（原已购买页面：授权范围 ）
          dataIndex: "auth_name",
          scopedSlots: { customRender: "auth_name" },
          width: "70%",
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          width: "10%",
        },
      ],
      handleData: {},
      showDialogActive: false,
    };
  },
  mixins: [musicHandle],

  props: {
    dataTableList: {
      type: Array,
    },
    pagination: {},
  },

  components: {
    ATable,
    ATooltip,
    DialogActiveAuth,
  },

  created: function () {},

  methods: {
    isPlaying(now_id) {
      let state = this.$store.state;
      if (!state.globalVideoInfo) return false;
      let { music_id, url } = state.globalVideoInfo;
      return state.globalVideoPlaying && music_id && url && now_id === music_id;
    },
    onPause() {
      this.$store.commit("changeVideoPlaying", false);
    },
    onClickIcon(item) {
      this.isPlaying(item.music_id) ? this.onPause() : this.onPlay(item);
      this.$store.commit("changeVideoList", this.list || []);
    },
    onPlay(item) {
      let musicInfo = item;
      musicInfo.url = musicInfo.audition_url;
      this.$store.commit("changeVideoInfo", item);
      this.$nextTick(() => {
        this.$store.commit("changeVideoPlaying", true);
      });
      this.goPlayMusic(musicInfo);
      this.play_music_id = musicInfo.music_id;
    },
    goToActivate(scope) {
      this.handleData = scope;
      this.showDialogActive = true;
    },
    onSuccess(scope) {
      this.$message.success("激活授权成功");
      //刷新页面todo
    },
  },
};
</script>

<style lang="scss" scoped>
.opera_img {
  text-align: center;
  min-width: 100px;
  img:not(:nth-child(1)) {
    display: none;
    width: 20px;
    position: absolute;
    margin-left: -53px;
    margin-top: 35px;
    cursor: pointer;
  }
  &:hover {
    img:not(:nth-child(1)) {
      display: inline-block;
    }
    .log_url_cell {
      opacity: 0.9;
    }
  }
}
.log_url_cell {
  width: 100%;
  // height: 90px;
  border-radius: 4px;
  cursor: pointer;
}
.logo-info {
  cursor: pointer;
}
</style>
